<template>
<div class="my-0 py-0" style="background: var(--v-component-base) !important">
    <v-row align="end" justify="end">
        <v-col cols="12" sm="12" md="8" lg="6">
            <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable placeholder="Search">
                <template slot="append">
                    <v-badge :content="computeFilterCount" :value="computeFilterCount > 0" color="primary" offset-y="10">
                        <el-button @click="filterDialog = !filterDialog" el-tooltip="Advanced Filter">
                            <v-icon small>filter_alt</v-icon>
                        </el-button>
                    </v-badge>
                </template>
            </el-input>
            <!-- Search Bar, Filter & Add -->
        </v-col>
    </v-row>
    <v-row class="mt-3">
        <v-col>
            <v-data-table :sort-by.sync="sortBy" @click:row="checkOrganisation" height="65vh" dense fixed-header hide-default-footer :loading="loading" :headers="headers" :items="organisations" :calculate-widths="true" style="cursor: pointer;" :options="{
                        page: page,
                        itemsPerPage: params.limit,
                        pageStart: page,
                        pageStop: Math.round(this.total / this.params.limit),
                        pageCount: Math.round(this.total / this.params.limit),
                        itemsLength: total
                    }">
                <template v-slot:[`item.isActive`]="{ item }">
                    <v-chip class="ma-2" small outlined :color="item.isActive ? 'success' : 'danger'">
                        <v-icon small left>{{ item.isActive ? 'check' : 'close' }}</v-icon>
                        {{ item.isActive ? 'Active' : 'Inactive' }}
                    </v-chip>
                </template>
                <template v-slot:[`item.icon`]="{ item }">
                    <v-avatar>
                        <v-img v-if="item.icon" :src="item.icon" contain></v-img>
                    </v-avatar>
                </template>
                <template v-slot:[`item.countryRegion.name`]="{ item }">
                    <v-avatar size="32" class="mr-1" v-if="item.countryCode">
                        <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.countryCode.toLowerCase()}.svg`">
                        </v-img>
                    </v-avatar>
                    <span v-if="item.countryRegion">
                        {{ item.countryRegion.name }}
                    </span>
                </template>
                <template v-slot:[`item.organisationType`]="{ item }">
                    <v-chip class="ma-2" small v-if="item.bank" outlined :color="item.bank ? 'success' : 'warning'">
                        Bank <v-icon small right>account_balance</v-icon>
                    </v-chip>
                    <v-chip class="ma-2" small v-if="item.buyer" outlined :color="item.buyer ? 'success' : 'warning'">
                        Buyer <v-icon small right>shopping_cart</v-icon>
                    </v-chip>
                    <v-chip class="ma-2" small v-if="item.coldstore" outlined :color="item.coldstore ? 'success' : 'warning'">Coldstore <v-icon small right>store
                        </v-icon>
                    </v-chip>
                    <v-chip class="ma-2" small v-if="item.consignee" outlined :color="item.consignee ? 'success' : 'warning'">Consignee <v-icon small right>
                            call_received
                        </v-icon>
                    </v-chip>
                    <v-chip class="ma-2" small v-if="item.forwarder" outlined :color="item.forwarder ? 'success' : 'warning'">Forwarder <v-icon small right>
                            fast_forward
                        </v-icon>
                    </v-chip>
                    <v-chip class="ma-2" small v-if="item.notifyParty" outlined :color="item.notifyParty ? 'success' : 'warning'">Notify Party <v-icon smallright>
                            notifications_active</v-icon>
                    </v-chip>
                    <v-chip class="ma-2" small v-if="item.producer" outlined :color="item.producer ? 'success' : 'warning'">
                        Producer <v-icon small right>factory</v-icon>
                    </v-chip>
                    <v-chip class="ma-2" small v-if="item.shipper" outlined :color="item.shipper ? 'success' : 'warning'">
                        Shipper <v-icon small right>local_shipping
                        </v-icon>
                    </v-chip>
                    <v-chip class="ma-2" small v-if="item.shippingLine" outlined :color="item.shippingLine ? 'success' : 'warning'">Shipping Line<v-icon small right>
                            directions_boat
                        </v-icon>
                    </v-chip>
                    <v-chip class="ma-2" small v-if="item.governingBody" outlined :color="item.governingBody ? 'success' : 'warning'">Governing Body<v-icon small right>
                            gavel
                        </v-icon>
                    </v-chip>
                    <v-chip class="ma-2" small v-if="item.pluginProvider" outlined :color="item.pluginProvider ? 'success' : 'warning'">Plugin Provider<v-icon small right>
                            extension
                        </v-icon>
                    </v-chip>
                    <v-chip class="ma-2" small v-if="item.transporter" outlined :color="item.transporter ? 'success' : 'warning'">Transporter<v-icon small right>
                            local_shipping
                        </v-icon>
                    </v-chip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-chip v-on="on" class="ma-2" small v-if="item.vasProvider" outlined :color="item.vasProvider ? 'success' : 'warning'">VAS Provider<v-icon small right>
                                    settings_suggest
                                </v-icon>
                            </v-chip>
                        </template>
                        <span>Value Added Service Provider</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" class="text-center">
            <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5" :page-size.sync="params.limit" :page-sizes="[10, 25, 50, 100]" :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper, total' : 'prev, pager, next'" :total="total"></el-pagination>

        </v-col>
    </v-row>

    <v-dialog v-model="filterDialog" persistent max-width="600px">
        <v-card dark :loading="loading">
            <v-toolbar>
                <v-card-title>
                    <span class="text-h5">Advanced filter</span>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" plain depressed elevation="0" :disabled="!filterActive" @click="clearFilters">
                            <v-icon>filter_alt_off</v-icon>
                        </v-btn>
                    </template>
                    <span>Clear Filters</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" plain depressed elevation="0" @click="filterSearch">
                            <v-icon>filter_alt</v-icon>
                        </v-btn>
                    </template>
                    <span>Apply Filters</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" plain depressed elevation="0" @click="closeFilterDialog()">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </template>
                    <span>Close</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-row justify="center">
                        <v-container fluid>
                            <v-chip class="ma-2" :outlined="searchItem.isActive ? false : true" @click="searchItem.isActive = !searchItem.isActive" color="success">
                                <v-icon small left>done</v-icon> Active
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.inActive ? false : true" @click="searchItem.inActive = !searchItem.inActive" color="danger">
                                <v-icon small left>close</v-icon> Inactive
                            </v-chip>
                        </v-container>
                    </v-row>
                    <v-row justify="center">
                        <v-container fluid>
                            <v-chip class="ma-2" :outlined="searchItem.bank ? false : true" :color="searchItem.bank ? 'success' : 'grey'" @click="searchItem.bank = !searchItem.bank">
                                Bank <v-icon small right>account_balance</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.buyer ? false : true" :color="searchItem.buyer ? 'success' : 'grey'" @click="searchItem.buyer = !searchItem.buyer">
                                Buyer
                                <v-icon small right>shopping_cart</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.coldstore ? false : true" :color="searchItem.coldstore ? 'success' : 'grey'" @click="searchItem.coldstore = !searchItem.coldstore">
                                Coldstore <v-icon small right>store</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.consignee ? false : true" :color="searchItem.consignee ? 'success' : 'grey'" @click="searchItem.consignee = !searchItem.consignee">
                                Consignee <v-icon small right>call_received</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.forwarder ? false : true" :color="searchItem.forwarder ? 'success' : 'grey'" @click="searchItem.forwarder = !searchItem.forwarder">
                                Forwarder <v-icon small right>fast_forward</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.notifyParty ? false : true" :color="searchItem.notifyParty ? 'success' : 'grey'" @click="searchItem.notifyParty = !searchItem.notifyParty">
                                Notify Party <v-icon small right>notifications_active</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.producer ? false : true" :color="searchItem.producer ? 'success' : 'grey'" @click="searchItem.producer = !searchItem.producer">
                                Producer <v-icon small right>factory</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.shipper ? false : true" :color="searchItem.shipper ? 'success' : 'grey'" @click="searchItem.shipper = !searchItem.shipper">
                                Shipper <v-icon small right>north_east</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.shippingLine ? false : true" :color="searchItem.shippingLine ? 'success' : 'grey'" @click="searchItem.shippingLine = !searchItem.shippingLine">
                                Shipping Line <v-icon small right>directions_boat</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.governingBody ? false : true" :color="searchItem.governingBody ? 'success' : 'grey'" @click="searchItem.governingBody = !searchItem.governingBody">
                                Governing Body <v-icon small right>gavel</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.pluginProvider ? false : true" :color="searchItem.pluginProvider ? 'success' : 'grey'" @click="searchItem.pluginProvider = !searchItem.pluginProvider">
                                Plugin Provider <v-icon small right>extension</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" :outlined="searchItem.transporter ? false : true" :color="searchItem.transporter ? 'success' : 'grey'" @click="searchItem.transporter = !searchItem.transporter">
                                Transporter <v-icon small right>local_shipping</v-icon>
                            </v-chip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip v-on="on" class="ma-2" :outlined="searchItem.vasProvider ? false : true" :color="searchItem.vasProvider ? 'success' : 'grey'" @click="searchItem.vasProvider = !searchItem.vasProvider">
                                        VAS Provider <v-icon small right>settings_suggest</v-icon>
                                    </v-chip>
                                </template>
                                <span>Value Added Service Provider</span>
                            </v-tooltip>
                        </v-container>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog fullscreen v-model="orgInfoDialog">
        <v-card dark :loading="loading">
            <v-toolbar flat>
                <v-toolbar-title>
                    <b class="mr-3">{{ this.selectedOrganisation.name }}</b>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="orgInfoDialog = !orgInfoDialog" icon>
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
                <v-tabs grow v-model="activeTab" height="30px">
                    <v-tab href="#info" @click="activeTab = 'info'"> Info </v-tab>
                    <v-tab href="#mapping" @click="activeTab = 'mapping'"> Mapping </v-tab>
                </v-tabs>
            <v-card-text style="max-height: 90vh; overflow-y: auto;">

                <v-tabs-items v-model="activeTab" class="my-0 py-0">
                    <v-tab-item value="info">
                        <EGOrgInfo :orgId="selectedOrganisation.id"></EGOrgInfo>
                    </v-tab-item>
                    <v-tab-item value="mapping">
                        <EGPlots :organisation="selectedOrganisation"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import EGPlots from "../EcertGlobal/EGPlots.vue"
import EGOrgInfo from "../EcertGlobal/EGOrgInfo.vue"

export default {
    components: {
        EGPlots,
        EGOrgInfo
    },
    data: () => ({
        activeTab: "#info",
        loadingIcon: false,
        loadingLogo: false,
        headers: [{
                text: 'Status',
                value: 'isActive',
                align: 'center',
            },
            {
                text: 'Icon',
                value: 'icon',
                align: 'center',
            },
            {
                text: 'Name',
                value: 'name',
                align: 'center',
            },
            {
                text: 'Country',
                value: 'countryRegion.name',
                sortable: false,
                align: 'center',
                width: '250px'
            },
            {
                text: 'Country Code',
                value: 'countryCode',
                sortable: false,
                align: 'center'
            },
            {
                text: 'Organisation Type',
                value: 'organisationType',
                sortable: false,
                align: 'center'
            },
            {
                text: 'Contact Email',
                value: 'emailAddress',
                align: 'center'
            },
            {
                text: 'Contact Phone',
                value: 'telephoneNumber',
                align: 'center'
            },
            {
                text: 'CBR ID',
                value: 'cbrId',
                align: 'center'
            },
            // {
            //     text: 'Company Type',
            //     value: 'companyType',
            //     align: 'center'
            // },
        ],
        loading: false,
        page: 1,
        params: {
            limit: 10,
            offset: 0,
            search: null,
            filter: {
                isActive: [true]
            }
        },
        orgInfoDialog: false,
        filterDialog: false,
        organisations: [],
        total: 100,
        searchItem: {
            isActive: true,
            inActive: false,
            bank: false,
            buyer: false,
            coldstore: false,
            consignee: false,
            forwarder: false,
            notifyParty: false,
            producer: false,
            shipper: false,
            shippingLine: false,
            pluginProvider: false,
            governingBody: false,
            transporter: false,
            vasProvider: false,
        },
        sortBy: 'name',
        sortDesc: false,
        filterActive: false,
        selectedOrganisation: {},
    }),
    computed: {
        computeFilterCount() {
            let count = 0
            for (var key in this.searchItem) {
                if (key == 'isActive' && !this.searchItem.isActive) {
                    count++
                }
                if (key == 'bank' && this.searchItem.bank) {
                    count++
                }
                if (key == 'buyer' && this.searchItem.buyer) {
                    count++
                }
                if (key == 'coldstore' && this.searchItem.coldstore) {
                    count++
                }
                if (key == 'consignee' && this.searchItem.consignee) {
                    count++
                }
                if (key == 'forwarder' && this.searchItem.forwarder) {
                    count++
                }
                if (key == 'notifyParty' && this.searchItem.notifyParty) {
                    count++
                }
                if (key == 'producer' && this.searchItem.producer) {
                    count++
                }
                if (key == 'shipper' && this.searchItem.shipper) {
                    count++
                }
                if (key == 'shippingLine' && this.searchItem.shippingLine) {
                    count++
                }
                if (key == 'pluginProvider' && this.searchItem.pluginProvider) {
                    count++
                }
                if (key == 'governingBody' && this.searchItem.governingBody) {
                    count++
                }
                if (key == 'transporter' && this.searchItem.transporter) {
                    count++
                }
                if (key == 'vasProvider' && this.searchItem.vasProvider) {
                    count++
                }
            }
            return count
        }
    },
    watch: {
        page: {
            handler: async function () {
                this.params.offset = (this.page - 1) * this.params.limit;
                // console.log(this.params.offset)
                this.fetchOrganisations()
            },
            immediate: true
        },
        "params.limit": {
            handler: async function () {
                this.params.offset = (this.page - 1) * this.params.limit;
                this.fetchOrganisations()
            },
            immediate: true
        },
        "params.search": {
            handler: function () {
                setTimeout(() => {
                    this.fetchOrganisations()
                }, 3000);
            },
            immediate: true
        },
        sortBy(value) {
            this.params.sortBy = value
            this.fetchOrganisations()
        },
        sortDesc(value) {
            this.params.sortDesc = value
            this.fetchOrganisations()
        },
    },
    async created() {
        this.fetchOrganisations()
    },
    mounted() {},
    methods: {
        async getCountryFlag(countryCode) {
            return `https://cdn.loglive.io/flags/4x3/${countryCode.toLowerCase()}.svg`;
        },
        async checkOrganisation(item) {
            console.log(item)
            this.selectedOrganisation = await this.$API.getEGOrgInfo({
                orgId: item.id
            })
            console.log({
                selectedOrganisation: this.selectedOrganisation
            })
            this.orgInfoDialog = !this.orgInfoDialog;
        },
        async fetchOrganisations() {
            this.loading = !this.loading
            let orgInfo = await this.$API.fetchEGOrganisations(this.params)
            this.organisations = orgInfo.organisations
            this.total = orgInfo.total
            this.loading = !this.loading
        },
        closeFilterDialog() {
            this.filterDialog = false
        },
        clearFilters() {
            this.filterActive = false
            this.clearFilterDialog()
            this.params.filter = {
                isActive: [true]
            }
            this.fetchOrganisations()
        },
        async clearFilterDialog() {
            this.filterDialog = false
            this.page = 1
            this.searchItem = {
                isActive: true,
                inActive: false,
                bank: false,
                buyer: false,
                coldstore: false,
                consignee: false,
                forwarder: false,
                notifyParty: false,
                producer: false,
                shipper: false,
                shippingLine: false,
                pluginProvider: false,
                governingBody: false,
                transporter: false,
                vasProvider: false
            };
        },
        async filterSearch() {
            this.filterActive = true
            this.params.filter = {}

            if (this.searchItem.isActive && this.searchItem.inActive) {
                this.params.filter.isActive = [true, false]
            } else if (this.searchItem.isActive && !this.searchItem.inActive) {
                this.params.filter.isActive = [true]
            } else if (!this.searchItem.isActive && this.searchItem.inActive) {
                this.params.filter.isActive = [false]
            }

            this.params.filter.bank = [this.searchItem.bank];
            this.params.filter.consignee = [this.searchItem.consignee];
            this.params.filter.buyer = [this.searchItem.buyer];
            this.params.filter.shipper = [this.searchItem.shipper];
            this.params.filter.producer = [this.searchItem.producer];
            this.params.filter.notifyParty = [this.searchItem.notifyParty];
            this.params.filter.coldstore = [this.searchItem.coldstore];
            this.params.filter.forwarder = [this.searchItem.forwarder];
            this.params.filter.shippingLine = [this.searchItem.shippingLine];
            this.params.filter.governingBody = [this.searchItem.governingBody];
            this.params.filter.pluginProvider = [this.searchItem.pluginProvider];
            this.params.filter.transporter = [this.searchItem.transporter];
            this.params.filter.vasProvider = [this.searchItem.vasProvider];

            this.params.filter = JSON.parse(JSON.stringify(this.params.filter))
            this.filterDialog = false
            this.fetchOrganisations()
        },
    }
};
</script>
