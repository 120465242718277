var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticStyle:{"background-color":"var(--v-background-base) !important"},attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"color":"greyBase","dark":"","extended":"","flat":"","extension-height":"55"}},[_c('v-toolbar-title',[_c('b',{staticClass:"mr-3"},[_vm._v("CBR")])])],1),_c('v-card',{staticClass:"mx-auto",style:({
            'margin-top': !_vm.$vuetify.breakpoint.mobile ? '-64px' : '15px',
            'border-radius': !_vm.$vuetify.breakpoint.mobile ? '50px' : '0px',
          }),attrs:{"max-width":!_vm.$vuetify.breakpoint.mobile ? '94vw' : '100%'}},[_c('v-card-text',{style:({
              height: !_vm.$vuetify.breakpoint.mobile ? '90vh' : '90vh',
              'overflow-y': 'auto',
            })},[_c('egOrganisations')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }