<template>
<div>
    <v-card flat style="background-color: var(--v-background-base) !important">
        <v-toolbar color="greyBase" dark extended flat extension-height="55">
            <v-toolbar-title>
                <b class="mr-3">CBR</b>
            </v-toolbar-title>
        </v-toolbar>

        <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
            'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
            'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
          }">
            <v-card-text :style="{
              height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
              'overflow-y': 'auto',
            }">
                <egOrganisations />

            </v-card-text>
        </v-card>
    </v-card>
</div>
</template>

  
  
<script>
import egOrganisations from "../components/EcertGlobal/EGOrganisations.vue";
export default {
    components: {
        egOrganisations,
    },
    data: () => ({
        loaded: true,
    }),
};
</script>
